@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Nanum+Gothic+Coding&family=Raleway:wght@300&display=swap');

.home-main-div {
  background-color: #161616;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
  font-family: 'Nanum Gothic Coding', monospace;
}

.home-left-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  align-items: center;
}

.profile-pic {
  height: 300px;
  width: 300px;
  border-radius: 200px;
}

.first-name {
  color: #F7F7F7;
  font-size: 100px;
  margin: 0;
}

.typewriter {
  color: #B7DBE7;
  font-size: 70px;
}

.home-right-div{
  display:flex;
  align-items: flex-end;
}

.home-right-div img {
  position: absolute;
  right: 4vw;
  }

/* ---------------------About------------------------- */

.about-main-div {
  background-color: #F8F9FA;
  width: 100%;
  text-align: center;
  font-family: 'Nanum Gothic Coding', monospace;
}

.tech-stack {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.project-tools {
  padding: 10px;
}

.stacks-main-div {
  background-color: #F8F9FA;
  width: 100%; 
  display: flex;
}

.about-title {
  display: block;
  width: 100%;
  margin-top: 15vh;
  background-color: #F8F9FA;
}

.desc-main {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0 !important;
}

.about-2-title {
  font-family: 'Major Mono Display', monospace;
  filter: drop-shadow(0 0 0.95rem #2bc4f7);
}

.about-me-desc {
  justify-content: right;
  /* width: 500px; */
  /* width: 30vw; */
  background-color: white;
  padding: 25px;
  font-family: 'Raleway', sans-serif;
  color: #000000;
  margin-top: 0;
}

.accolades {
  font-family: 'Raleway', sans-serif;
  
}

.Acc-color {
  color: #2bc4f7;
}

.cards {
  background-color: white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.201);
  /* margin-top: 5vh;
  margin-bottom: 12vh; */
  padding: 15px;
  /* height: 16.8vh; */
  margin: 5vh;
  width: 30vw;
}

.general-about {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 17px 9px -7px rgba(0,0,0,0.35);
  /* height: 70vh; */
  height: fit-content;
  margin-top: 7vh;
  width: 40vw;
}

.me-card-img {
  width: 100%;
  height: 370px;
  margin-bottom: 0;
}

/* .highlight1 {
  background-color: #2bc4f7;
} */

.btnfos-1 {
  background: #2bc4f7;
  font-weight: 100;
}
.btnfos-1 svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 45px;
}
.btnfos-1 rect {
  fill: none;
  stroke: #2bc4f7;
  stroke-width: 1;
  stroke-dasharray: 422, 0;
}

.btnfos-1:hover {
  background: rgba(225, 51, 45, 0);
  letter-spacing: 1px;
  font-weight: 900;
  color: #2bc4f7;
}
.btnfos-1:hover rect {
  stroke-width: 5;
  stroke-dasharray: 15, 310;
  stroke-dashoffset: 48;
  -webkit-transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
}

.btnfos {
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 45px;
  max-width: 160px;
  margin: 0 auto 2em;
  position: relative;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
}

.projects-main-title {
  color: #B7DBE7;
  font-family: 'Major Mono Display', monospace;
}

.whiteruler {
  background-color: white;
  width: 100%;
  margin-bottom: 5vh;
}

.projects-title-div {
  display: inline-flex;
  padding-top: 5vh;
}

.sweatmage {
  padding-right: 1vw;
}

/* -------------------Projects--------------------------- */

.flip-card {
  background-color: transparent;
  width: 400px;
  height: 300px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; 
  margin: 2vh;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #F8F9FA;
  color: #161616;
  transform: rotateY(180deg);
}

.imporium {
  height: 100%;
  width: 100%;
}

.ref-link {
  position: relative;
  margin: 1vw;
  text-decoration: none;
  background-color: #161616;
  color: #F8F9FA;
  padding: 2vh 2vw;
  top: 5vh;
}

.proj-pad {
  padding-left: .7vw;
  padding-right: .7vw;
}

/* -------------------Contact--------------------------- */

.contact-main-div {
  background-color: #F8F9FA;
  width: 100%;
  text-align: center;
}

.contact-title {
  font-family: 'Major Mono Display', monospace;
  filter: drop-shadow(0 0 0.95rem #2bc4f7);
  margin-top: 2vh;
  font-size: 2em;
  margin-bottom: 2vh;
}

.contact-main-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 5vh;
}

.coolmage {
  transform: scaleX(-1);
}

.contact-main-body {
  font-size: 1.5em;
}

.contact-sub-sub {
  width: 40vw;
}

.contact-card {
  /* background-color: #161616; */
  height: 50vh 50vw; 
}

.icon-div {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20vh;
}

.icons {
  height: 10vh;
  /* padding: 1vw; */
}

/* -------------------Media Queries--------------------------- */

@media only screen and (max-width: 768px) {
  .home-left-div {
    height: 100vh;
  }
  .general-about {
    width: 70vw;
  }
  .cards {
    width: 70vw;
  }
  .contact-sub-sub {
    width: 70vw;
    margin-bottom: 0;
  }
  .icon-div {
    margin-top: 4vh;
  }
  .first-name, .last-name {
    font-size: 80px;
  }
}

@media only screen and (max-width: 720px) {
  .home-left-div {
    height: 70vh;
  }
  .general-about {
    width: 70vw;
  }
  .cards {
    width: 70vw;
  }
  .contact-sub-sub {
    width: 70vw;
    margin-bottom: 0;
  }
  .icon-div {
    margin-top: 4vh;
  }
  .about-title {
    margin-top: 1vh;
  }
  .general-about {
    margin-top: 1vh;
  }
  .flip-card{
    width: 75vw;
    height: 30vh;
  }
  .typewriter {
    font-size: 42px;
  }
  .first-name, .last-name {
    font-size: 80px;
  }
  .first-name {
    margin-top: 1vh;
  }
}

@media only screen and (max-width: 414px) {
  .dancemage {
    height: 18vh;
  }
  .typewriter{
    font-size: 25px;
  }
  .general-about{
    width: 88vw;
  }
  .me-card-img {
    height: 30vh;
  }
  .about-title {
    margin-top: 1vh;
  }
  .flip-card {
    width: 80vw;
  }
  .coolmage{
    width: 100%;
  }
  .icon-text {
    font-size: 16px;
  }
  .contact-sub-sub {
    text-align: center;
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .dancemage {
    height: 18vh;
  }
  .typewriter{
    font-size: 25px;
  }
  .general-about{
    width: 88vw;
  }
  .me-card-img {
    height: 30vh;
  }
  .about-title {
    margin-top: 1vh;
  }
  .flip-card {
    width: 80vw;
  }
  .coolmage{
    width: 100%;
  }
  .icon-text {
    font-size: 16px;
  }
  .contact-sub-sub {
    text-align: center;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .home-left-div {
    height: 100vh;
  }
  .general-about {
    width: 70vw;
  }
  .cards {
    width: 70vw;
  }
  .contact-sub-sub {
    /* width: 70vw; */
    margin-bottom: 0;
    margin-right: 0;
  }
  .icon-div {
    margin-top: 4vh;
  }
  .first-name, .last-name {
    font-size: 80px;
  }
  .ref-link {
    margin-bottom: 5vh;
    top: 1.4vh;
  }
}