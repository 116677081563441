.project-tools {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 88px;
  gap: 15px;
  font-size: 12px;
  font-family: "Noto Sans Mono", monospace;
}

.project-tools svg {
  width: 30px;
  display: block;
  display: flex;
  flex: 1 1 auto;
}