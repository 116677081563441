.nav-div {
  background-color: #161616;
  height: 150px;
  width: 100vw;
  display: inline-flex;
  justify-content: space-between;
  padding-top: 2vh;
}

.home-link, .home-link-2, .project-link, .about-me-link, .resume-link, .contact-link {
  text-decoration: none;
  color: #B7DBE7;
  font-size: 25px;
  margin-top: 35px;
  margin-left: 4vw;
  padding-right: 4vw;
}

.home-div-right {
  display: flex;
  justify-content: flex-end;
}

.home-div-left {
  display: flex;
  justify-content: flex-start;
}

@media only screen and (max-width: 768px) {
  .home-link, .home-link-2, .project-link, .about-me-link, .resume-link, .contact-link {
    margin-left: 0vw;
  }
}

@media only screen and (max-width: 720px) {
  .home-link, .home-link-2, .project-link, .about-me-link, .resume-link, .contact-link {
    margin-left: 0vw;
    font-size: 24px;
  }
}

@media only screen and (max-width: 414px) {
  home-link, .home-link-2, .project-link, .about-me-link, .resume-link, .contact-link {
    margin-left: 0;
    font-size: 18px;
    display: block;
    margin-top: 0;
    padding-top: 5px;
  }
}

@media only screen and (max-width: 375px) {
  home-link, .home-link-2, .project-link, .about-me-link, .resume-link, .contact-link {
    margin-left: 0;
    font-size: 15px;
    display: block;
    margin-top: 0;
    padding-top: 5px;
  }
}